import Activity from 'components/Activity/Activity'
import React from 'react'
import data from 'assets/experiences.json'
import styles from './style.module.scss'
import { differenceInMonths } from 'date-fns'

const getSeniority = (dateFrom: string) => {
  const dateStart = new Date(dateFrom)
  const currentDate = new Date()
  const totalMonths = differenceInMonths(currentDate, dateStart)

  const years = Math.floor(totalMonths / 12)
  const months = totalMonths % 12

  return years ? `${years} années et ${months} mois` : `${months} mois`
}

export const Experiences = () => {
  const { experiences } = data

  return (
    <>
      {experiences.map(experience => (
        <div key={experience.id} className={styles.jobContent}>
          <img
            className={styles.img}
            alt={experience.image}
            src={experience.image}
          />
          <h3 className={styles.h3}>
            {experience.dateFrom
              ? getSeniority(experience.dateFrom)
              : experience.seniority}
          </h3>
          {experience.activities.map(activity => (
            <Activity key={activity.job} activity={activity} />
          ))}
        </div>
      ))}
    </>
  )
}
