import React, { useState } from 'react'
import styles from './style.module.scss'

export const Contact = () => {
  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    phoneNumber: '',
  }

  const [formData, setFormData] = useState(defaultFormData)

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    const { firstName, lastName, email, message } = formData
    const subject = `Prise de contact : ${firstName} ${lastName}`
    const body = `Nom: ${lastName}
Prénom: ${firstName}
Email: ${email}

Message:
${message}`

    const mailtoLink = `mailto:clement.baby9@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.open(mailtoLink)
  }

  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Nom"
          required
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div className={styles.formSection}>
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="Prénom"
          value={formData.firstName}
          required
          onChange={handleChange}
        />
      </div>
      <div className={styles.formSection}>
        <input
          type="text"
          id="email"
          name="email"
          placeholder="Email"
          required
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className={styles.formSection}>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Numéro de téléphone"
          required
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      <div className={styles.formSection}>
        <textarea
          id="message"
          name="message"
          rows={3}
          placeholder="Message"
          required
          value={formData.message}
          onChange={handleChange}
        />
      </div>

      <button
        disabled={Object.values(formData).some(v => v === '')}
        onClick={handleSubmit}
        className={styles.button}
      >
        Envoyer
      </button>
    </div>
  )
}
