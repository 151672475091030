import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { ContentPage } from 'components/ContentPage/ContentPage'
import { Experiences } from 'components/Experiences/Experiences'
import Formation from 'components/Formation/Formation'
import { Home } from 'components/Home/Home'
import { PageEnum } from 'domain/enum'
import React from 'react'
import { Contact } from 'components/Contact/Contact'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route
          path={`/${PageEnum.Experiences}`}
          element={
            <ContentPage>
              <Experiences />
            </ContentPage>
          }
        />
        <Route
          path={`/${PageEnum.Formation}`}
          element={
            <ContentPage>
              <Formation />
            </ContentPage>
          }
        />
        <Route
          path={`/${PageEnum.Contact}`}
          element={
            <ContentPage>
              <Contact />
            </ContentPage>
          }
        />
      </Routes>
    </Router>
  )
}

export default App
